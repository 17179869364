import { useAuthGoogle } from "app/hooks/useAuthGoogle";
import { useQueryListAdmin } from "app/hooks/useQueryListEmail";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { UseQueryResult } from "react-query";

export interface AuthGoogleContextInterface {
  login?: boolean;
  setLogin: Dispatch<SetStateAction<boolean | undefined>>;
  listDataAdminPartner: UseQueryResult<string[], unknown>;
  isLoading: boolean;
}
interface IProps {
  children: React.ReactNode;
}

const AuthGoogleContext = React.createContext<AuthGoogleContextInterface>({} as AuthGoogleContextInterface);

export const AuthGoogleProvider: React.FC<IProps> = ({ children }) => {
  const [login, setLogin] = React.useState<boolean | undefined>(undefined);
  const localStorageAccessToken = localStorage.getItem("accessToken");
  const dataListAdmin = useQueryListAdmin();
  const authGoogleData = useAuthGoogle(localStorageAccessToken);

  React.useEffect(() => {
    console.log("Google authen data => ", authGoogleData);
    console.log("Data list admin => ", dataListAdmin);
    if (!localStorageAccessToken) setLogin(false);
    if (authGoogleData.data === null) {
      localStorage.removeItem("accessToken");
      setLogin(false);
    }
    if (!authGoogleData.data) return;
    if (
      (authGoogleData.data.hd === "myria.com" && process.env.REACT_APP_INTERNAL_SITE === "true") ||
      (dataListAdmin.data && dataListAdmin.data.includes(authGoogleData.data.email.toLowerCase()) && process.env.REACT_APP_INTERNAL_SITE !== "true")
    ) {
      setLogin(true);
    } else {
      localStorage.removeItem("accessToken");
      setLogin(false);
    }
  }, [authGoogleData.data, dataListAdmin.data]);

  return (
    <AuthGoogleContext.Provider
      value={{
        login,
        setLogin,
        listDataAdminPartner: dataListAdmin,
        isLoading: authGoogleData.isLoading || dataListAdmin.isLoading,
      }}
    >
      {children}
    </AuthGoogleContext.Provider>
  );
};
export const useAuthGoogleContext = () => useContext(AuthGoogleContext);
