import { queryKey } from "app/utils/constant";
import { checkAuthStatus } from "app/utils/pipeline";
import { useQuery } from "react-query";

export const useAuthGoogle = (access_token: string | null) => {
  return useQuery(
    [queryKey.getTransactionAPI, access_token],
    async () => {
      try {
        if (access_token) {
          const getData = await checkAuthStatus(access_token);
          if (getData === undefined) {
            return null;
          }
          return getData;
        } else {
          return null;
        }
      } catch {
        return null;
      }
    },
    {
      enabled: !!access_token,
    }
  );
};
