import React, { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { UserDataResponse } from "myria-core-sdk";
import { toast } from "react-toastify";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { changeNetwork, USER_MESSAGE_REGISTER } from "../app/utils/Web3Utils";
import { getModuleFactory, signMetamask } from "app/service/myriaCodeSDK";
import useLocalStorage from "app/hooks/useLocalStorgae";
import { localStorageKeys } from "app/utils/constant";

interface IAuthenticationWalletContext {
  isConnected: boolean;
  starkKey: string;
  walletAddress: string;
  connectL2Wallet: () => Promise<any>;
  disconnectL2Wallet: () => Promise<void>;
}

const AuthenticationWalletContext = React.createContext<IAuthenticationWalletContext>({} as IAuthenticationWalletContext);

interface IProps {
  children: React.ReactNode;
}
const ENV_CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);

export const AuthenticationWalletProvider: React.FC<IProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [starkKey, setStarkKey] = useState<string>("0x");
  const [walletAddress, setWalletAddress] = useState<string>("0x");
  const [localStarkKey, setLocalStarkKey] = useLocalStorage(localStorageKeys.starkKey, "");
  const [localWalletAddress, setLocalWalletAddress] = useLocalStorage(localStorageKeys.walletAddress, "");

  const getProviderOptions = () => ({
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: process.env.REACT_APP_INFURA_ID,
      },
    },
  });
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts: any) {
        toast("Account is changed !", {
          type: "warning",
        });
        disconnectL2Wallet();
      });
    }
    window.ethereum.on("chainChanged", function (networkId) {
      toast("Network changed. Logging out", {
        type: "warning",
      });
      disconnectL2Wallet();
    });
  }, []);

  useEffect(() => {
    const validateLogin = async () => {
      if (typeof window.ethereum !== "undefined") {
        const accounts = await signMetamask();
        if (!accounts || accounts.length === 0) return;
        if (localWalletAddress && localStarkKey && accounts[0].toLowerCase() === localWalletAddress.toLowerCase()) {
          setIsConnected(true);
          setStarkKey(localStarkKey);
          setWalletAddress(localWalletAddress);
        } else {
          setLocalStarkKey("");
          setLocalWalletAddress("");
        }
      }
    };
    validateLogin();
  }, []);

  const loginL2Wallet = async (metamaskAccount: string): Promise<UserDataResponse | undefined> => {
    const moduleFactory = await getModuleFactory();
    if (!moduleFactory) return;
    const userModule = moduleFactory.getUserManager();
    try {
      const user = await userModule.getUserByWalletAddress(metamaskAccount);
      if (user && user?.ethAddress?.toLowerCase() === metamaskAccount?.toLowerCase()) {
        return user;
      } else {
        return undefined;
      }
    } catch {
      toast("Login Failed, This Account is not existed. Please register on the Myria website.", {
        type: "error",
      });
      return undefined;
    }
  };

  async function connectL2Wallet() {
    const tWebModal = new Web3Modal({
      network: process.env.NETWORK_TYPE,
      cacheProvider: true,
      providerOptions: getProviderOptions(),
    });
    const provider = await tWebModal.connect();
    const providerApi = new ethers.providers.Web3Provider(provider);
    const network = await providerApi.getNetwork();
    if (network.chainId !== ENV_CHAIN_ID) {
      await changeNetwork(provider, ENV_CHAIN_ID);
    }
    if (typeof window.ethereum !== "undefined") {
      const accounts = await signMetamask();
      if (!accounts || accounts.length === 0) return;
      const currentAccount = accounts[0];
      const moduleFactory = await getModuleFactory();
      if (!moduleFactory) return;
      const commonModule = moduleFactory.getCommonModule();
      const getStarkKey = await commonModule.generateStarkKey(currentAccount);
      const statusLoginL2Wallet = await loginL2Wallet(currentAccount);
      if (statusLoginL2Wallet === undefined) return;
      if (statusLoginL2Wallet && "0x" + getStarkKey === statusLoginL2Wallet.starkKey) {
        //Keep status login
        toast("Login Success", {
          type: "success",
        });
        setIsConnected(true);
        setWalletAddress(statusLoginL2Wallet.ethAddress);
        setStarkKey(statusLoginL2Wallet.starkKey);
        setLocalStarkKey(statusLoginL2Wallet.starkKey);
        setLocalWalletAddress(statusLoginL2Wallet.ethAddress);
      } else {
        toast("Login failed, The stark key is mismatch. Please switch with correct wallet.", {
          type: "error",
        });
        disconnectL2Wallet();
      }
    } else {
      toast(USER_MESSAGE_REGISTER.noDetectedMetamask, {
        type: "warning",
      });
    }
  }
  async function disconnectL2Wallet() {
    setIsConnected(false);
    setWalletAddress("0x");
    setStarkKey("0x");
    setLocalStarkKey("");
    setLocalWalletAddress("");
  }

  return (
    <AuthenticationWalletContext.Provider
      value={{
        isConnected,
        starkKey,
        walletAddress,
        connectL2Wallet,
        disconnectL2Wallet,
      }}
    >
      {children}
    </AuthenticationWalletContext.Provider>
  );
};

export const useAuthWallet = () => useContext(AuthenticationWalletContext);
