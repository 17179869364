import React from "react";
import { Modules } from "app/service/helper";
import { GetCollectionParams, CollectionListResponse } from "myria-core-sdk";
import { BallTriangle } from "react-loader-spinner";
import Collection from "app/components/Collection";
import { env } from "config";
import Alert from "app/components/Alert";
import PaginationButton from "app/components/Buttons/PaginationButtons";
import { useHistory } from "react-router-dom";
import { useAuthGoogle } from "app/hooks/useAuthGoogle";
import { useAuthGoogleContext } from "context/AuthenticateGoogleContext";

export default function CollectionsPage(): React.ReactElement {
  const [collections, setCollections] = React.useState<CollectionListResponse[]>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [disablePrevButton, setDisablePrevButton] = React.useState(false);
  const [disableNextButton, setDisableNextButton] = React.useState(false);
  const [pageList, setPageList] = React.useState<string[]>([]);
  const [loadingCollection, setLoadingCollection] = React.useState(false);
  const history = useHistory();
  const pageRef = React.useRef<HTMLDivElement>(null);
  const modules = new Modules(env);
  const accessToken = localStorage.getItem("accessToken");
  const authGoogleData = useAuthGoogle(accessToken);
  const { login, isLoading } = useAuthGoogleContext();
  React.useEffect(() => {
    if (process.env.REACT_APP_INTERNAL_SITE === "false") {
      history.push("/admin-tools/distribute-airdrop");
    }
  }, []);

  const getCollectionList = async (params: GetCollectionParams) => {
    if (accessToken) {
      setLoadingCollection(true);
      await authGoogleData.refetch();
      try {
        if (login) {
          const collecitonList = await modules.getCollections(params);
          if (collecitonList?.items && collecitonList.items.length > 0) {
            const link = collecitonList?.links.last;
            const lastPageNumber = String(String(link).split("?page=")[1]).split("&limit=")[0];
            setCollections(collecitonList?.items);
            setLastPage(Number(lastPageNumber));
            setPageList(new Array(Number(lastPageNumber)).fill(0));
          }
          setLoadingCollection(false);
        } else {
          setLoadingCollection(false);
        }
      } catch {
        setLoadingCollection(false);
      }
    } else {
      console.log("No Access Token");
    }
  };
  React.useEffect(() => {
    if (process.env.REACT_APP_INTERNAL_SITE === "false") {
      return;
    }
    if (login) {
      setDisablePrevButton(true);
      getCollectionList({ page: 1 });
    }
  }, [login]);

  React.useEffect(() => {
    if (!login) return;
    if (process.env.REACT_APP_INTERNAL_SITE === "false") {
      return;
    }
    if (currentPage > 1) {
      setDisablePrevButton(false);
    }
    if (currentPage >= lastPage) {
      setDisableNextButton(true);
    }
    if (currentPage < lastPage) {
      setDisableNextButton(false);
    }
    if (currentPage <= 1) {
      setDisablePrevButton(true);
    }
    setCollections([]);
    getCollectionList({ page: currentPage });
  }, [currentPage, lastPage, login]);

  const handlePrevPage = async () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
  };

  return login ? (
    <div>
      <div className="text-white flex flex-wrap justify-around mt-9" ref={pageRef}>
        {(isLoading || loadingCollection) && (
          <div className="w-full h-screen flex flex-col text-white text-xl md:text-3xl font-bold justify-center items-center">
            <BallTriangle height={100} width={100} radius={5} color="purple" ariaLabel="ball-triangle-loading" visible={true} />
          </div>
        )}
        {collections &&
          collections.length > 0 &&
          collections.map((collection, index) => {
            return <Collection collectionInfo={collection} key={index}></Collection>;
          })}
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          disabled={disablePrevButton}
          className={`bg-yellow-600 hover:bg-yellow-300 disabled:bg-header-background rounded-l-full w-24 p-3 my-4 ${
            String(disablePrevButton) === "true" ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => handlePrevPage()}
        >
          Prev
        </button>

        <PaginationButton currentPage={currentPage} pageList={pageList} setCurrentPage={setCurrentPage} />
        <button
          disabled={disableNextButton}
          className={`bg-yellow-600 hover:bg-yellow-300 disabled:bg-header-background rounded-r-full p-3 w-24 my-4 ${
            String(disableNextButton) === "true" ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => handleNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  ) : login === false ? (
    <Alert></Alert>
  ) : (
    <div className="w-full h-screen flex flex-col text-white text-xl md:text-3xl font-bold justify-center items-center">
      <BallTriangle height={100} width={100} radius={5} color="purple" ariaLabel="ball-triangle-loading" visible={true} />
    </div>
  );
}
