import Alert from "app/components/Alert";
import { useAuthGoogleContext } from "context/AuthenticateGoogleContext";
import React from "react";
import { BallTriangle } from "react-loader-spinner";

interface Props {
  children: React.ReactNode;
}

const LayoutAuth: React.FC<Props> = ({ children }) => {
  const { login, isLoading } = useAuthGoogleContext();
  return login ? (
    <div className="max-w-7xl m-auto min-h-[calc(100vh-96px)] py-6">
      <div className="bg-header-background min-h-[calc(100vh-96px-48px)] px-4 rounded-lg">
        {isLoading ? (
          <div className="w-full h-screen flex flex-col text-white text-xl md:text-3xl font-bold justify-center items-center">
            <BallTriangle height={100} width={100} radius={5} color="purple" ariaLabel="ball-triangle-loading" visible={true} />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  ) : login === false ? (
    <Alert></Alert>
  ) : (
    <div className="w-full h-screen flex flex-col text-white text-xl md:text-3xl font-bold justify-center items-center">
      <BallTriangle height={100} width={100} radius={5} color="purple" ariaLabel="ball-triangle-loading" visible={true} />
    </div>
  );
};

export default LayoutAuth;
