import React from "react";
import { CheckCircleOutlined, CopyOutlined, ExclamationCircleOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { QUERY_TRANSACTIONS_INTERVAL, arrayFieldCopy, dataIndexDefault, handlerCopy, statusBulk } from "app/utils/constant";
import { editWalletAddress } from "app/utils/pipeline";
import { TokenType } from "myria-core-sdk";
import { useParams } from "react-router";
import { validate } from "uuid";
import TableComp, { columnMyria, columnNFT } from "../../TablesComp";
import LoadingDistribute from "../ExportData/LoadingDistribute";
import { useQueryTransaction } from "../../../hooks/useQueryTransaction";

interface IUserPublicProfileRouteParams {
  groupRequestID: string;
}

export const handleStatusRender = {
  [statusBulk.completePending]: {
    status: statusBulk.completeSuccess,
    functionRender(handlerClickRetry: any) {
      return <CheckCircleOutlined className="flex text-green-600 text-xl" title={"Completed"} style={{ height: "unset", paddingTop: "0" }} />;
    },
  },
  [statusBulk.completeSuccess]: {
    status: statusBulk.completeSuccess,
    functionRender(handlerClickRetry: any) {
      return <CheckCircleOutlined className="flex text-green-600 text-xl" title={"Success"} style={{ height: "unset", paddingTop: "0" }} />;
    },
  },
  [statusBulk.inProgress]: {
    status: statusBulk.inProgress,
    functionRender(handlerClickRetry: any) {
      return (
        <div className="w-5 flex items-center">
          <Loading3QuartersOutlined className="flex animate-spin text-xl my-auto" title={"In Progress"} style={{ height: "unset", paddingTop: "0" }} />
        </div>
      );
    },
  },
  [statusBulk.failed]: {
    status: statusBulk.failed,
    functionRender(handlerClickRetry: any) {
      return (
        <ExclamationCircleOutlined
          className="text-red-500 hover:opacity-80 text-xl"
          title={"Retry"}
          style={{ height: "unset", paddingTop: "0" }}
          onClick={handlerClickRetry}
        ></ExclamationCircleOutlined>
      );
    },
  },
  [statusBulk.failedGateWay]: {
    status: statusBulk.failedGateWay,
    functionRender(handlerClickRetry: any) {
      return (
        <ExclamationCircleOutlined
          className="text-red-500 flex justify-center hover:opacity-80 text-xl"
          title={"Failed"}
          style={{ height: "unset", paddingTop: "0" }}
        ></ExclamationCircleOutlined>
      );
    },
  },
};

const RetryDistributedTokenComponent = () => {
  let { groupRequestID } = useParams<IUserPublicProfileRouteParams>();
  const dataTransaction = useQueryTransaction(groupRequestID, undefined, true, QUERY_TRANSACTIONS_INTERVAL);

  const handleRenderColumn = (text: string, record: any, dataIndex: dataIndexDefault) => {
    return (
      <div className="editable-cell-value-wrap flex items-center">
        <span>{dataIndex === dataIndexDefault.smartContract || (text && text.length > 42) ? editWalletAddress(text) : text}</span>
        {arrayFieldCopy.includes(dataIndex) ? <CopyOutlined className="p-2 hover:cursor-pointer" onClick={() => handlerCopy(text)} /> : null}
      </div>
    );
  };
  const handlerClickRetry = () => {
    console.log("retry");
  };

  const renderRetryDistributeBulk = (status: statusBulk) => {
    return handleStatusRender[status].functionRender(handlerClickRetry);
  };

  if (!validate(groupRequestID)) return null;
  if ((dataTransaction.isFetching || dataTransaction.isLoading) && !dataTransaction.data) {
    return <LoadingDistribute />;
  } else {
    return (
      <div className="min-w-[1112px]">
        <div className="flex justify-center items-center text-2xl font-semibold pt-4">
          <h1 className="mx-4">Distribute AirDrop Dashboard</h1>
        </div>
        <Row gutter={16} justify="start">
          {groupRequestID ? (
            <Col span={12} style={{ display: "flex", justifyContent: "start", paddingTop: "50px" }}>
              <h2 className="mr-3 text-base">Group Request ID:</h2>
              <span className="text-base">{groupRequestID}</span>
            </Col>
          ) : null}
        </Row>
        {dataTransaction.data && dataTransaction.data.items.length > 0 ? (
          <div className="min-w-[1212px]">
            {dataTransaction.data.items[0].tokenType === TokenType.ERC20 || dataTransaction.data.items[0].tokenType === TokenType.MINTABLE_ERC20 ? (
              <TableComp
                rows={dataTransaction.data.items ?? []}
                column={columnMyria}
                pagination={{
                  position: ["bottomRight", "topRight"],
                  // onChange: onChangePage,
                  defaultPageSize: 100,
                }}
              />
            ) : (
              <TableComp
                rows={dataTransaction.data.items ?? []}
                column={columnNFT}
                pagination={{
                  position: ["bottomRight", "topRight"],
                  // onChange: onChangePage,
                  defaultPageSize: 100,
                }}
              />
            )}
          </div>
        ) : (
          <div className="mt-4 min-w-[1212px]">
            <TableComp
              rows={[]}
              column={columnNFT}
              pagination={{
                position: ["bottomRight", "topRight"],
                // onChange: onChangePage,
                defaultPageSize: 100,
              }}
            />
          </div>
        )}
      </div>
    );
  }
};

export default RetryDistributedTokenComponent;
