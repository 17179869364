import axios from "axios";
import { InforEmailRespond } from "./constant";

const editWalletAddress = (address: string): string => {
  if (address && address.length > 5) {
    const prefix = address.slice(0, 4);
    const suffix = address.slice(-4);
    return prefix + "..." + suffix;
  } else {
    return address;
  }
};

const getListEmailPartner = async (): Promise<string[]> => {
  const listDataEmailPartner = await axios.get(process.env.REACT_APP_LIST_EMAIL_VALIDATE_URL ?? "");
  const dataEmail = listDataEmailPartner.data.data
    .filter((partnerObject: any) => {
      return partnerObject.attributes.UseAirdropTool === true;
    })
    .map((partnerObject: any) => {
      return partnerObject.attributes.Partner_Email.toLowerCase();
    });
  console.log("Authorized partner => ", dataEmail);
  return dataEmail;
};

const checkAuthStatus = async (accessToken: string): Promise<InforEmailRespond | undefined> => {
  try {
    const result = await axios.get(process.env.REACT_APP_GOOGLE_API_URL ?? "", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === "401") {
        return undefined;
      }
    }
  }
};

export { editWalletAddress, checkAuthStatus, getListEmailPartner };
