import { getTransaction, queryKey } from "app/utils/constant";
import { getListEmailPartner } from "app/utils/pipeline";
import { useQuery } from "react-query";

export const useQueryListAdmin = () => {
  return useQuery([queryKey.getListEmailAdmin], async () => {
    try {
      const getData = await getListEmailPartner();
      return getData;
    } catch {
      return [];
    }
  });
};
